<template>
  <div id="template-repository-wrapper">
    <PageTitle :title="title" />
    <div>
      <el-tabs
        v-model="activeName"
        class="template-tab-box"
        @tab-click="handleClick"
      >
        <el-tab-pane label="系统" name="system">
          <TemplateBox templateType="system" />
        </el-tab-pane>
        <el-tab-pane label="我的" name="user">
          <TemplateBox templateType="user" />
        </el-tab-pane>
      </el-tabs>
    </div>
    <div></div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineEmits } from "vue";
import TemplateBox from "./components/TemplateBox.vue";
import PageTitle from "@/components/base/PageTitle";
const title = "模板库";
const activeName = ref("system");
const handleClick = (tab, event) => {
  console.log(tab, event);
};
// 面包屑
const emits = defineEmits(["setBreadcrumbList"]);
const setBreadcrumbList = () => {
  let BreadcrumbList = [{ title: "模板库" }];
  emits("setBreadcrumbList", BreadcrumbList);
};
setBreadcrumbList();
</script>

<style lang="scss" scoped>
:deep(.el-tabs__header) {
  margin: 0;
}
.template-tab-box {
  margin-top: -10px;
}
</style>
