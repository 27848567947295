<template>
  <div v-if="ifSystemTemplate" class="template-card-wrapper system-template">
    <div class="icon-box">
      <el-tooltip :content="templateTip" :disabled="!templateTip">
        <i
          :class="['iconfont', 'tab-icon', icon]"
          :style="{ color: iconcolor }"
        ></i>
      </el-tooltip>

      <div>{{ cardInfo.name }}</div>
    </div>

    <div class="option-btn-box">
      <div class="option-btn" @click="handleClickUse">使用</div>
    </div>
  </div>
  <div
    v-else
    :class="[
      'template-card-wrapper',
      'user-template',
      { active: ifHighlight == true }
    ]"
    @click="getifHighlight(true)"
    tabindex="1"
  >
    <div class="icon-box">
      <el-tooltip placement="top" effect="light">
        <template #content>
          <span>点击可更换模板图标</span>
        </template>
        <i
          :class="['iconfont', 'tab-icon', icon]"
          :style="{ color: iconcolor }"
        ></i>
      </el-tooltip>
      <div>
        {{ cardInfo.name }}
        <i class="iconfont icon-bianji"></i>
      </div>
    </div>

    <div v-if="ifCustomize" class="option-btn-box">
      <div class="option-btn">使用</div>
      <div class="option-btn">编辑</div>
      <div class="option-btn">删除</div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, computed, defineEmits } from "vue";
import * as analysisTemplate from "@/constant/analysis_template";
import { SYSTEM_USER_ID } from "@/constant/system";
const emits = defineEmits(["use"]);
const props = defineProps({
  cardInfo: {
    default: {
      name: "情感分析",
      templateAnalysisId: 1
    }
  }
});
const visible = ref(false);
const icon = ref();
const ifHighlight = ref(false); //当前高亮卡片

let templateAnalysisId = ref(props.cardInfo.templateAnalysisId);
const getIcon = (id) => {
  let template = analysisTemplate.ANALYSIS_TEMPALTE_MAP[id];

  return template ? template.icon : "icon-wodemoban";
};

icon.value = getIcon(templateAnalysisId.value);
const getIconColor = (id) => {
  let template = analysisTemplate.ANALYSIS_TEMPALTE_MAP[id];
  return template ? template.color : "#101010";
};

const iconcolor = ref(getIconColor(templateAnalysisId.value));
const getifHighlight = (val) => {
  ifHighlight.value = val;
};

const getTemplateTip = (id) => {
  let template = analysisTemplate.ANALYSIS_TEMPALTE_MAP[id];

  return template ? template.tip : "";
};
const templateTip = ref(getTemplateTip(templateAnalysisId.value));

const ifSystemTemplate = computed(() => {
  return props.cardInfo.userId == SYSTEM_USER_ID;
});
const ifCustomize = computed(() => {
  console.log(
    "custom",
    props.cardInfo.templateAnalysisId,
    analysisTemplate.ANALYSIS_TEMPALTE.CUSTOMIZE.value
  );
  return (
    props.cardInfo.templateAnalysisId ==
    analysisTemplate.ANALYSIS_TEMPALTE.CUSTOMIZE.value
  );
});

// 点击使用
const handleClickUse = () => {
  emits("use", props.cardInfo);
};
</script>
<style lang="scss" scoped>
.template-card-wrapper {
  width: 225px;
  height: 200px;
  @include default-border();
  display: flex;
  flex-direction: column;
  align-items: center;
  @include round-corner(5px);
  .icon-box {
    flex: 1;
    text-align: center;
    padding: 32px 0 24px;
    .tab-icon {
      font-size: 50px;
    }
    div {
      margin-top: 20px;
    }
  }
  .option-btn-box {
    display: none;
  }
}
.system-template:hover {
  @include default-border($theme-color);
  @include pointer();
  .icon-box {
    color: $theme-color;
  }
  .option-btn-box {
    display: block;
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: $theme-color;
    color: $main-page-background;
    @include round-corner(5px, ("bottom-right", "bottom-left"));
    .option-btn {
      width: 100%;
      height: 100%;
      line-height: 44px;
      text-align: center;
      @include pointer();
      &:nth-child(2) {
        @include default-border(
          $main-page-background,
          $directions: ("left", "right")
        );
      }
    }
  }
}
.user-template {
  .icon-box {
    .tab-icon:hover {
      @include pointer();
    }
  }
}
.active {
  // &:active {
  //   @include default-border($theme-color);
  //   .icon-box {
  //     color: $theme-color;
  //   }
  //   .option-btn-box {
  //     display: block;
  //     width: 100%;
  //     height: 44px;
  //     background-color: $theme-color;
  //     color: $main-page-background;
  //     @include round-corner(5px, ("bottom-right", "bottom-left"));
  //     .option-btn {
  //       width: 100%;
  //       height: 100%;
  //       line-height: 44px;
  //       text-align: center;
  //       @include pointer();
  //       &:nth-child(2) {
  //         @include default-border(
  //           $main-page-background,
  //           $directions: ("left", "right")
  //         );
  //       }
  //     }
  //   }
  // }
  &:focus {
    @include default-border($theme-color);
    .icon-box {
      color: $theme-color;
    }
    .option-btn-box {
      display: block;
      width: 100%;
      height: 44px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      background-color: $theme-color;
      color: $main-page-background;
      @include round-corner(5px, ("bottom-right", "bottom-left"));
      .option-btn {
        width: 100%;
        height: 100%;
        line-height: 44px;
        text-align: center;
        @include pointer();
        &:nth-child(2) {
          @include default-border(
            $main-page-background,
            $directions: ("left", "right")
          );
        }
      }
    }
  }
}
</style>
